body{
  div.mat-mdc-autocomplete-panel{
    --mat-autocomplete-background-color:white;
  }
  .mat-mdc-menu-panel{
    --mat-menu-container-color:white;
  }
  div.mat-mdc-select-panel{
    --mat-select-panel-background-color:white;
  }
  .cdk-overlay-pane.mat-mdc-dialog-panel{
    max-width:unset;
  }
  .mat-stepper-vertical,
  .mat-stepper-horizontal{
    --mat-stepper-container-color:white;
  }
  .mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field .mdc-notched-outline__notch{
    border-right:none;
  }
  .mat-mdc-select{
    // --mat-option-label-text-size:14px;
    // --mat-select-trigger-text-line-height:1.2rem;
    --mat-option-label-text-size:.8rem;
    --mat-select-trigger-text-size:.8rem;
    --mat-option-label-text-size:.8rem;
  }
  .mat-mdc-option{
    --mat-option-label-text-size:.8rem;
    min-height: 38px;
  }
  .mat-mdc-dialog-container-with-actions .mat-mdc-dialog-content{
    --mat-dialog-with-actions-content-padding: 16px 19.2px;
  }
  .mat-bottom-sheet-container{
    --mat-bottom-sheet-container-background-color: #ffffff;
  }
  .mat-mdc-form-field-infix{
    --mat-form-field-container-height:45px;
  }
  .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-floating-label{
    --mat-form-field-container-height:45px;
  }
  .mdc-text-field--outlined .mat-mdc-form-field-infix,
  .mdc-text-field--no-label .mat-mdc-form-field-infix{
    --mat-form-field-container-vertical-padding:.75rem;
    --mat-form-field-container-vertical-padding:.75rem;
  }
  .mat-mdc-form-field {
    --mat-form-field-container-text-size: 0.875rem;
  }

  .mat-mdc-form-field {
    &.explore-page-mat-form-field .mdc-text-field--outlined {
      .mat-mdc-select-arrow-wrapper {
        height: 2rem;
      }
      .mat-mdc-form-field-infix {
        --mat-form-field-container-vertical-padding: 0rem;
        --mat-form-field-container-height: 2rem;
        --mat-select-enabled-arrow-color: #1d1b20;
      }
      .mat-mdc-form-field-flex .mdc-floating-label.mat-mdc-floating-label {
        --mat-form-field-container-height: 2rem;
        font-weight: 500;
      }
    }
  }
  mat-form-field.explore-page-mat-form-field .mat-mdc-text-field-wrapper{background:white;}
}
